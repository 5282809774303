@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bulma@0.8.2/css/bulma.min.css');
@import url("https://cdn.jsdelivr.net/npm/bulma-divider@0.2.0/dist/css/bulma-divider.min.css");

html,
body {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth !important;
    transition: all 0.25s ease;
}

/* width */
::-webkit-scrollbar {
    width: 0.5vmax !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: white !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #363636 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: black !important;
}

::placeholder {
    font-family: 'Poppins', sans-serif;
}

#navbar {
    .navbar-burger {
        span:nth-child(2) {
            left: calc(50%);
            width: 8px;
        }
    }
}

.is-active {
    font-weight: bold;
}

.columns {
    .button {
        margin: 0.5rem !important;
    }
}

hr {
    margin: 1rem auto;
    height: 1px;
    background-color: #dbdbdb;
}

.column {
    margin: auto 0.5rem;
    padding: 0.5rem;
}

.searchResult {
    a {
        .title {
            text-decoration: underline;
        }
    }
}